<template>
  <div>
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
    <list-service :servicesList="listServices" v-else></list-service>
  </div>
</template>

<script>
import listService from '@src/components/additionalServices/listService.vue'

import { paymentComputed, paymentMethods } from '@state/helpers'
export default {
  components: {
    listService,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...paymentComputed,
    listServices() {
      return this.companyPaymentInfo.currentSubscription.plan.additional
        .filter((item) => this.filterAdditional(item))
        .filter((item) => this.filterActive(item))
    },
  },
  methods: {
    ...paymentMethods,
    filterAdditional(item) {
      return String(item.classification).toLowerCase() == 'adicional'
    },
    filterActive(item) {
      return !item.isBlocked
    },
  },
  async mounted() {
    this.loading = true
    await this.getPaymentData()
    this.loading = false
  },
}
</script>

<style lang="scss" scoped></style>
