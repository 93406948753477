<template>
  <div class="pa-6">
    <v-row justify="space-between">
      <div
        :class="{ 'pr-4': $vuetify.breakpoint.mdAndUp }"
        class="order-2 order-sm-2 order-md-0 order-lg-0 align-self-center flex-grow-1"
      >
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busque pelo serviço"
          single-line
          hide-details
        ></v-text-field>
      </div>
      <div
        :class="{ 'mb-3': $vuetify.breakpoint.smAndDown }"
        class="order-1 order-sm-1 order-md-2 align-self-end"
      >
        <v-btn
          :class="{ 'ma-0': $vuetify.breakpoint.smAndDown }"
          color="blue darken-3"
          dark
          small
          :to="{ name: 'AdditionalServices' }"
        >
          Meus Serviços Adicionais
        </v-btn>
      </div>
      <VSpacer />
      <VSpacer />
    </v-row>
    <v-row>
      <v-data-iterator
        :search="search"
        sort-by="name"
        class="mt-6"
        :items="servicesList"
        :items-per-page.sync="itemsPerPage"
        hide-default-footer
        style="width: 100%"
      >
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              sm="6"
              md="3"
            >
              <v-card height="100%" class="d-flex" elevation="2">
                <v-row
                  justify="space-between"
                  class="pa-3 font-weight-bold grey--text text--darken-2 flex-column"
                >
                  <v-col>
                    <span>{{ item.name }} </span>
                  </v-col>
                  <v-col>
                    <span class="mb-1">Descrição</span>
                    <br />
                    <span class="font-weight-normal grey--text">
                      {{ item.description | truncate(100) }}
                    </span>
                  </v-col>

                  <v-col v-if="$vuetify.breakpoint.mdAndUp">
                    Prazo em dias úteis:
                    <span class="font-weight-regular">{{ item.deadline }}</span>
                  </v-col>

                  <v-col>
                    <div v-if="item.isBlocked">
                      Bloqueado no seu Plano
                      <br />
                      <a @click="$router.push({ name: 'comparativePlan' })">
                        Conheça os demais planos
                      </a>
                    </div>
                    <template v-else>
                      <div v-if="item.limitDemarcation === 'unlimited'">
                        incluso
                      </div>
                      <div v-if="item.limitDemarcation === 'not applicable'">
                        <span v-if="item.value > 0">
                          Valor: {{ item.value | currencyFromCents }}
                        </span>
                        <span v-else>Sob consulta</span>
                      </div>
                      <div
                        v-if="
                          item.limitDemarcation === 'fixed' &&
                          !checkIfPassedLimit(item.requestCount, item.limit)
                        "
                      >
                        Limite de contratação {{ item.limit }}
                        {{ item.limitType === 'month' ? 'por mês' : 'por ano' }}
                        <!-- Ultrapassou o limite de contratação -->
                      </div>
                      <div
                        v-if="
                          item.limitDemarcation === 'fixed' &&
                          checkIfPassedLimit(item.requestCount, item.limit)
                        "
                      >
                        Valor: {{ item.value | currencyFromCents }}
                      </div>
                    </template>
                  </v-col>

                  <v-col class="" v-if="!item.isBlocked">
                    <v-btn
                      dark
                      color="success"
                      :to="{
                        name: 'newService',
                        params: { idService: item.id },
                      }"
                    >
                      selecione
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-col cols="12">
            <div class="text-center pt-2">
              <v-btn color="info" v-show="showBtn" @click="itemsPerPage = -1">
                visualizar mais
              </v-btn>
            </div>
          </v-col>
        </template>
      </v-data-iterator>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    servicesList: {
      type: Array,
      default: () => {
        return []
      },
      required: true,
    },
  },
  data() {
    return {
      itemsPerPage: 8,
      search: null,
      prevRoute: null,
    }
  },
  computed: {
    showBtn() {
      if (this.itemsPerPage === -1) return false
      if (this.itemsPerPage >= this.servicesList.length) return false
      return true
    },
  },
  methods: {
    checkIfPassedLimit(requestCount, limit) {
      return requestCount > limit
    },
  },
  mounted() {
    this.search = this.$route.query.filter || null
  },
}
</script>

<style lang="scss" scoped>
@import '@design';

a {
  color: #0d5f18;
  text-decoration: none;
}
</style>
